import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Spinner from 'reactstrap/lib/Spinner'
import { getRecommendationsRow } from '../apis/home'
import LayoutMinimal from "../components/LayoutMinimal"


const RowFlat = ({movies}) => {
    const [maxWidth, setMaxWidth] = useState(200)
    if (!movies || !movies.length){
        return <Spinner size="sm" className="m-2"/>
    }

    const items = movies.map(i => (
        <Link to={i.slug} key={i.id} className="m-2 shadow rounded-sm underline-not">
            <img src={i.poster_thumb.url} width={maxWidth} height={maxWidth*1.43}/>
            <div className="py-2 px-3" style={{maxWidth: maxWidth}}>
                <h5 className="font-weight-bold text-dark underline-not" >{i.title}</h5>
            </div>
        </Link>
    ))
    return (
        <div className=" d-flex flex-direction-row justify-content-center flex-wrap">
            {items}
        </div>
    )
}


const Page404 = () => {
    const [recommendations, setRecommendations] = useState([])
    
    const init = async () => {
        try {
            const row = await getRecommendationsRow("", "/", 'videos')
            setRecommendations(row[0].movies)
            console.log(row[0].movies)
        } catch (err){
            console.log(err)
        }
    }

    useEffect(() => {
        init()
    },[])

    const browser = typeof window !== "undefined" && window;
    if (!browser) return ""

    return (
        <LayoutMinimal>
            <div className="text-center mx-auto ">
                <h1 className="font-weight-bold mt-5">
                    Il n'y a rien ici...
                </h1>
                <p>Cette page n'existe pas ou son contenu a été déplacé (404) </p>
            </div>

            {/* <div className="m-5">
                <h3 className="font-weight-bold mt-5">
                    Mais ces videos pourraient vous intéresser:
                </h3>
                <div>
                    <RowFlat movies={recommendations}/>
                </div>
            </div> */}

        </LayoutMinimal>
    )
}

export default Page404